body, html, #root, .app {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
}

.app {
  display: flex;
  flex-direction: row;
}

.main-content {
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

