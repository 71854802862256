.second-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fbf6ed;
  text-align: center;
}

h1 {
	color: #a3742c;
	margin: 0;
}

h3 {
	font-weight: 400;
	letter-spacing:7px;
	font-family: 'Zesta-Italic', serif;
	display: block;
	position: relative;
	padding-right: 20px;
    padding-left: 20px;
}