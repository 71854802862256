@font-face {
  font-family: Zesta-Italic;
  src: url('zesta-italic.otf');
}

@font-face {
  font-family: Begum-Regular;
  src: url('begum.ttf');
}

.fourth-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fbf6ed;
  text-align: center;
}

h1 {
	color: #a3742c;
	font-family: 'Begum-Regular', serif;
	font-weight: 400;
	margin: 0;
	letter-spacing:3px;
}
h3 {
	font-weight: 400;
	letter-spacing:7px;
	font-family: 'Zesta-Italic', serif;
	display: block;
	position: relative;
	padding-right: 20px;
    padding-left: 20px;
}

h3::before{
	    width: 100px;
    border-style: solid;
	border-width: 0;
    border-bottom-width: 1px;
	right: 100%;
	content: "";
	display: block;
    top: 50%;
	position: absolute;
}

h3::after{
	    width: 100px;
    border-style: solid;
	border-width: 0;
    border-bottom-width: 1px;
	left: 100%;
	content: "";
	display: block;
    top: 50%;
	position: absolute;
}

.contact {
	background-color: #B5B0A9;
}

.inner-contact{
	display: flex;
	width: 75%;
	gap: 25px;
}

.input-contact{
	width: 100%;
	/*background-color: #4b4f54;*/
	padding: 15px;
}

.input-contact>form{
	display: flex;
	flex-direction: column;
	gap: 25px;
	width: 100%;
}

.input-contact input{
	height: 32px;
	width: 100%;
}

.input-contact textarea{
	height: 120px;
	font-size: 17px;
	resize:none;
}

.info-contact{
	width:35%;
	/*background-color: #4b4f54;*/
	padding: 15px;
	align-content: center;
}

.feedbackPlaceHolder {
  margin: 12px 0;
  font-size: 16px;
}

.successText {
  margin: 12px 0;
  font-size: 16px;
  color: #84e384;
  font-weight: bold;
  text-align: center;
}

.errorText {
  margin: 12px 0;
  font-size: 16px;
  color: red;
  font-weight: bold;
  animation: shake 0.3s ease-in-out;
  text-align: center;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(5px);
  }
  40% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(5px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.sendButtonDisabled {
  color: #fff;
  display: inline-block;
  background-color: #8c8c8c;
  padding: 9px 40px 14px;
  font-style: italic;
  width: fit-content !important;
}

.sendButton{
	color: #fff;
}

.sendButton{
	text-decoration: none;
	cursor: pointer;
  display: inline-block;
  background-color: #000;
  padding: 9px 40px 14px;
  font-style: italic;
  transition: all .3s ease;
}

.sendButton:hover {
  letter-spacing: 2px;
}

.sendButton span{
	text-decoration: none;
}